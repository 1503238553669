import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import TabTwo from "../elements/tab/TabTwo";
import Contact from "../elements/contact/Contact";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";

import profielfoto from "../assets/images/pasfoto.jpg";
import contactImg from "../assets/images/background.jpg";

const SlideList = [
  {
    textPosition: "text-left",
    category: "Welcome to my World",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];
const PortfolioLanding = () => {
  let title = "Wie ben ik",
    description =
      `Vanaf een jonge leeftijd heb ik een diepgaande fascinatie voor computers gekoesterd, wat uiteindelijk mijn 
      professionele pad heeft bepaald. Mijn passie voor teamwork en mijn natuurlijke neiging om als spil te fungeren, 
      hebben me altijd gedreven. Ik hecht grote waarde aan benaderbaarheid en eerlijkheid, en ik ben vastbesloten om 
      een blijvende indruk achter te laten.
      
      In mijn vrije tijd werk ik aan diverse hobbyprojecten, waarbij mijn ondernemende geest goed van pas komt. 
      Daarnaast ben ik altijd gefocust op voortdurende zelfverbetering en het blijven ontwikkelen van mijn vaardigheden.
      `;
  return (
    <div className="active-dark">
      <Helmet pageTitle="Portfolio Landing" />

      <Header homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix">
        
        <div className="slider-wrapper">

          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}
            >
              
              <div className="container">
                
                <div className="row">
                  
                  <div className="col-lg-12">
                    
                    <div className={`inner ${value.textPosition}`}>
                      
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        Soufyane Tichattibin <br />
                        <TextLoop>
                          <span> .NET Engineer.</span>
                          <span> Cloud Engineer.</span>
                          <span> Full stack engineer.</span>
                        </TextLoop>{" "}
                      </h1>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img className="w-100" src={profielfoto} alt="Profielfoto" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

      {/* Start Service Area  */}
      <div id="service" className="fix">
        <div className="service-area creative-service-wrapper ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                  <h2 className="title">Wat kan ik</h2>
                  <p>
                    Er is veel wat ik kan en veel wat ik niet kan, 
                    een paar van de dingen die ik wel zijn zijn als volgt:
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Area  */}

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Projectervaring</h2>
                    <p>
                      Projecten waar ik aan bij heb mogen dragen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
              {/* <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--60 mt_sm--30 text-center">
                    <a className="rn-button-style--2 btn-solid" href="/blog">
                      <span>View More</span>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}


      {/* Start Contact Area */}
      <div className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <Contact contactImages={contactImg} contactTitle="Neem contact op." />
        </div>
      </div>
      {/* End Contact Area */}

      <Footer />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default PortfolioLanding;
