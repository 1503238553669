import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

class TabsTwo extends Component {
  render() {
    let tab1 = "Specialiteit",
      tab2 = "Werkervaring",
      tab3 = "Onderwijs & Certificaten"
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    <Tab>{tab2}</Tab>
                    <Tab>{tab3}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a >
                            .NET Development <span> - Core / Framework</span>
                          </a>
                          Sterke achtergrond in .NET development.
                        </li>
                        <li>
                          <a >
                            Cloud Development
                            <span> - Azure</span>
                          </a>
                          Werkervaring waarin ook een groot Azure migratieproject.
                        </li>
                        <li>
                          <a >
                            Front end development <span> - Angular - React - Vue </span>
                          </a>
                          Werkervaring met elk van de grote frontend frameworks.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                  <div className="single-tab-content">
                      <ul>
                        <li>
                          <a >
                            Cloud / Backend Engineer<span> - Unive (via Info Support)</span>
                          </a>{" "}
                          sept. 2021 -  apr. 2024
                        </li>
                        <li>
                          <a href="https://www.infosupport.com" target="_blank" rel="noopener noreferrer">
                            DevOps Consultant<span> - Info Support</span>
                          </a>{" "}
                          feb. 2020 - sept. 2021
                        </li>
                        <li>
                          <a href="https://www.rysst.nl" target="_blank" rel="noopener noreferrer">
                            Jr. Frontend Software Engineer<span> - RYSST </span>
                          </a>{" "}
                          jul. 2018 - jan. 2020
                        </li>
                        <li>
                          <a href="https://www.propertynl.com" target="_blank" rel="noopener noreferrer">
                            Jr. Fullstack Software Engineer<span> - PropertyNL </span>
                          </a>{" "}
                          sept. 2018 -  jan. 2019
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a >
                            HBO-ICT<span> - Hogeschool Utrecht</span>
                          </a>{" "}
                          2016 - 2020
                        </li>
                        <li>
                          <a >
                          Continuous Delivery in Large and Complex Software Systems<span> - Info Support</span>
                          </a>{" "}
                          2019 - 2020
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default TabsTwo;
