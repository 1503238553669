import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'infosupport',
        category: 'DevOps Consultancy',
        title: 'Info Support DevOps Consultancy'
    },
    {
        image: 'unive',
        category: 'Cloud / Backend Development',
        title: 'Unive Cloud migratie'
    },
    {
        image: 'propertynl',
        category: 'Full stack Development',
        title: 'PropertyNL CMS modernisatie'
    },
    {
        image: 'mendrix',
        category: 'Frontend Development',
        title: 'Mendrix Track and trace'
    },
    {
        image: 'unit4',
        category: 'Frontend Development',
        title: 'Unit4 Accountancy Suite'
    },
    {
        image: 'bijenkorf',
        category: 'Frontend Development',
        title: 'Bijenkorf Email Templater'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a >{value.title}</a></h4>
                                    {/* <div className="portfolio-button">
                                        <a className="rn-btn" href="/portfolio-details">Details</a>
                                    </div> */}
                                </div>
                            </div>
                            {/* <Link className="link-overlay" to="/portfolio-details/"></Link> */}
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;