// React Required
import React, { Component } from "react";
// import ReactDOM from 'react-dom';
import { render } from 'react-dom';

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Dark Home Layout
import DarkPortfolioLanding from "./dark/PortfolioLanding";

// Element Layout
import PortfolioDetails from "./elements/PortfolioDetails";
import error404 from "./elements/error404";

// Blocks Layout

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

const root = document.getElementById('root'); // <- This is the //correct method call for React version 17
render(
  <React.StrictMode>
    <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding} />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/portfolio-details`}
              component={PortfolioDetails}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
  </React.StrictMode>, root);

serviceWorker.register();
